// GiftExpenseSplitter.jsx
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Switch,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  Container,
  Paper,
  Grid,
  Box,
} from '@mui/material';
import { Euro, Users, FileText } from 'lucide-react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

// Styles für das PDF
const styles = StyleSheet.create({
  page: { padding: 30 },
  title: { fontSize: 24, marginBottom: 20, textAlign: 'center', fontWeight: 'bold' },
  subtitle: { fontSize: 18, marginBottom: 10, fontWeight: 'bold' },
  text: { fontSize: 12, marginBottom: 5 },
  rowContainer: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 },
});

// PDF Document Component
const PDFDocument = ({ participants, totalAmount, finalShares, participantNames }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Geschenkausgaben-Übersicht</Text>

      <View style={styles.rowContainer}>
        <Text style={styles.text}>Gesamtbetrag:</Text>
        <Text style={styles.text}>{parseFloat(totalAmount).toFixed(2)} €</Text>
      </View>

      <View style={styles.rowContainer}>
        <Text style={styles.text}>Anzahl der Teilnehmer:</Text>
        <Text style={styles.text}>{participants}</Text>
      </View>

      {finalShares.length > 0 && (
        <>
          <Text style={[styles.subtitle, { marginTop: 20 }]}>Aufteilung:</Text>
          {finalShares.map((share, index) => (
            <View key={index} style={styles.rowContainer}>
              <Text style={styles.text}>{participantNames[index] || `Teilnehmer ${index + 1}`}:</Text>
              <Text style={styles.text}>{parseFloat(share).toFixed(2)} €</Text>
            </View>
          ))}
        </>
      )}
    </Page>
  </Document>
);

// Schritt 1: Eingabe des Gesamtbetrags
const Step1 = ({ totalAmount, handleTotalAmountChange, error, nextStep }) => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Schritt 1: Gesamtbetrag eingeben
    </Typography>
    <TextField
      label="Gesamtbetrag (€)"
      variant="outlined"
      value={totalAmount}
      onChange={handleTotalAmountChange}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Euro size={20} />
          </InputAdornment>
        ),
      }}
      helperText="Bitte gebe den Geschenkbetrag ein."
      error={!!error}
    />
    {error && (
      <Typography color="error" variant="body2">
        {error}
      </Typography>
    )}
    <Box mt={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={nextStep}>
        Weiter
      </Button>
    </Box>
  </Box>
);

// Schritt 2: Festlegung der Anzahl der Teilnehmer
const Step2 = ({ participants, handleParticipantsChange, error, prevStep, nextStep }) => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Schritt 2: Anzahl der Teilnehmer festlegen
    </Typography>
    <TextField
      label="Anzahl der Teilnehmer"
      variant="outlined"
      type="number"
      inputProps={{ min: 2 }}
      value={participants}
      onChange={handleParticipantsChange}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Users size={20} />
          </InputAdornment>
        ),
      }}
      helperText="Bitte geben Sie die Anzahl der Teilnehmer ein."
      error={!!error}
    />
    {error && (
      <Typography color="error" variant="body2">
        {error}
      </Typography>
    )}
    <Box mt={2} display="flex" justifyContent="space-between">
      <Button variant="outlined" onClick={prevStep}>
        Zurück
      </Button>
      <Button variant="contained" color="primary" onClick={nextStep}>
        Weiter
      </Button>
    </Box>
  </Box>
);

// Schritt 3: Individuelle Aufteilung festlegen
const Step3 = ({
  participantNames,
  handleNameChange,
  isManualInput,
  handleManualInputChange,
  manualShares,
  handleShareChange,
  finalShares,
  isValid,
  error,
  prevStep,
  nextStep,
  participants,
}) => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Schritt 3: Individuelle Aufteilung festlegen
    </Typography>
    <FormGroup>
      {participantNames.map((_, index) => (
        <Grid container spacing={2} alignItems="center" key={index}>
          <Grid item xs={12} sm={4}>
            <TextField
              label={`Teilnehmer ${index + 1} Name`}
              variant="outlined"
              value={participantNames[index] || ''}
              onChange={(e) => handleNameChange(index, e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isManualInput[index]}
                  onChange={(e) => handleManualInputChange(index, e.target.checked)}
                  color="primary"
                />
              }
              label="Manuell"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {isManualInput[index] ? (
              <TextField
                label="Betrag (€)"
                variant="outlined"
                value={manualShares[index] || ''}
                onChange={(e) => handleShareChange(index, e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Euro size={20} />
                    </InputAdornment>
                  ),
                }}
                error={!isValid && !!manualShares[index]}
                helperText={!isValid && !!manualShares[index] ? error : ''}
              />
            ) : (
              <Typography>Automatisch berechnet: {finalShares[index]} €</Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </FormGroup>
    {!isValid && error && (
      <Typography color="error" variant="body2">
        {error}
      </Typography>
    )}
    <Box mt={2} display="flex" justifyContent="space-between">
      <Button variant="outlined" onClick={prevStep}>
        Zurück
      </Button>
      <Button variant="contained" color="primary" onClick={nextStep} disabled={!isValid}>
        Weiter
      </Button>
    </Box>
  </Box>
);

// Schritt 4: Zusammenfassung und PDF-Download
const Step4 = ({
  totalAmount,
  participants,
  finalShares,
  participantNames,
  prevStep,
}) => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Schritt 4: Zusammenfassung
    </Typography>
    <Paper variant="outlined">
      <Box p={2}>
        <Typography variant="subtitle1" gutterBottom>
          Details
        </Typography>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Gesamtbetrag:</Typography>
          <Typography>{parseFloat(totalAmount.replace(',', '.')).toFixed(2)} €</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Anzahl der Teilnehmer:</Typography>
          <Typography>{participants}</Typography>
        </Box>
      </Box>
    </Paper>
    <Paper variant="outlined" style={{ marginTop: 16 }}>
      <Box p={2}>
        <Typography variant="subtitle1" gutterBottom>
          Aufteilung
        </Typography>
        {finalShares.map((share, index) => (
          <Box key={index} display="flex" justifyContent="space-between" mb={1}>
            <Typography>{participantNames[index] || `Teilnehmer ${index + 1}`}</Typography>
            <Typography>{parseFloat(share).toFixed(2)} €</Typography>
          </Box>
        ))}
      </Box>
    </Paper>
    <Box mt={2} display="flex" justifyContent="space-between">
      <Button variant="outlined" onClick={prevStep}>
        Zurück
      </Button>
      <PDFDownloadLink
        document={
          <PDFDocument
            participants={participants}
            totalAmount={parseFloat(totalAmount.replace(',', '.')) || 0}
            finalShares={finalShares}
            participantNames={participantNames}
          />
        }
        fileName="geschenkausgaben-uebersicht.pdf"
        style={{ textDecoration: 'none' }}
      >
        {({ loading }) =>
          loading ? (
            <Button variant="contained" color="primary" disabled>
              Lade PDF...
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              <FileText style={{ marginRight: 8 }} />
              PDF herunterladen
            </Button>
          )
        }
      </PDFDownloadLink>
    </Box>
  </Box>
);

const GiftExpenseSplitter = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [totalAmount, setTotalAmount] = useState('');
  const [participants, setParticipants] = useState(2);
  const [participantNames, setParticipantNames] = useState(['', '']);
  const [manualShares, setManualShares] = useState(['', '']);
  const [finalShares, setFinalShares] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [isManualInput, setIsManualInput] = useState([false, false]);

  const steps = ['Gesamtbetrag', 'Teilnehmer', 'Aufteilung', 'Zusammenfassung'];

  // Berechnung und Validierung der Anteile
  useEffect(() => {
    const amount = parseFloat(totalAmount.replace(',', '.'));
    if (isNaN(amount) || amount <= 0) {
      setIsValid(false);
      setError('');
      return;
    }

    // Bestimme manuell eingegebene Anteile
    const manualValues = manualShares.map((share, index) => {
      if (isManualInput[index]) {
        const parsedShare = parseFloat(share.toString().replace(',', '.'));
        return !isNaN(parsedShare) && share !== '' ? parsedShare : 0;
      } else {
        return null;
      }
    });

    const totalManual = manualValues.reduce((acc, val) => acc + (val !== null ? val : 0), 0);

    const remainingParticipants = participants - manualValues.filter((share) => share !== null).length;

    const remainingAmount = amount - totalManual;

    if (remainingParticipants < 0 || remainingAmount < 0) {
      setIsValid(false);
      setError('Die Summe der eingegebenen Beträge übersteigt den Gesamtbetrag.');
      return;
    }

    let autoShare =
      remainingParticipants > 0 ? Math.floor((remainingAmount / remainingParticipants) * 100) / 100 : 0;

    // Baue das finale Shares-Array
    const computedShares = manualValues.map((share) => {
      return share !== null ? share : autoShare;
    });

    // Anpassung für Rundungsfehler
    const totalShares = computedShares.reduce((acc, val) => acc + val, 0);
    let difference = Math.round((amount - totalShares) * 100) / 100;

    if (difference !== 0) {
      // Passe den ersten automatischen Anteil an
      for (let i = 0; i < participants; i++) {
        if (!isManualInput[i]) {
          computedShares[i] += difference;
          break;
        }
      }
    }

    // Setze die finalShares
    setFinalShares(computedShares.map((share) => share.toFixed(2)));

    // Überprüfe die Gesamtsumme
    const updatedTotal = computedShares.reduce((acc, val) => acc + val, 0);
    const finalDifference = Math.round((amount - updatedTotal) * 100) / 100;

    if (finalDifference === 0) {
      setIsValid(true);
      setError('');
    } else {
      setIsValid(false);
      setError('Fehler bei der Berechnung der Anteile.');
    }
  }, [manualShares, totalAmount, participants, isManualInput]);

  // Handler für die Änderung des Gesamtbetrags
  const handleTotalAmountChange = (e) => {
    setTotalAmount(e.target.value);
  };

  // Handler für die Änderung der Anzahl der Teilnehmer
  const handleParticipantsChange = (e) => {
    setParticipants(e.target.value);
  };

  // Handler für die Änderung der Teilnehmernamen
  const handleNameChange = (index, value) => {
    const newNames = [...participantNames];
    newNames[index] = value;
    setParticipantNames(newNames);
  };

  // Handler für die Änderung der individuellen Anteile
  const handleShareChange = (index, value) => {
    const newManualShares = [...manualShares];
    newManualShares[index] = value;
    setManualShares(newManualShares);
  };

  // Handler für die Änderung der manuellen Eingabe
  const handleManualInputChange = (index, checked) => {
    const newIsManualInput = [...isManualInput];
    newIsManualInput[index] = checked;
    setIsManualInput(newIsManualInput);

    if (!checked) {
      // Lösche den Anteil, wenn die manuelle Eingabe deaktiviert wird
      const newManualShares = [...manualShares];
      newManualShares[index] = '';
      setManualShares(newManualShares);
    }
  };

  // Navigation zwischen den Schritten
  const nextStep = () => {
    // Validierung vor dem Fortschreiten zum nächsten Schritt
    if (currentStep === 0) {
      const amount = parseFloat(totalAmount.replace(',', '.'));
      if (isNaN(amount) || amount <= 0) {
        setError('Bitte geben Sie einen gültigen Gesamtbetrag ein.');
        return;
      } else {
        setError('');
      }
    }

    if (currentStep === 1) {
      const numParticipants = parseInt(participants);
      if (isNaN(numParticipants) || numParticipants < 2) {
        setError('Bitte geben Sie eine gültige Teilnehmerzahl (mindestens 2) ein.');
        return;
      } else {
        // Aktualisiere Teilnehmernamen
        const newNames = [...participantNames];
        while (newNames.length < numParticipants) {
          newNames.push('');
        }
        while (newNames.length > numParticipants) {
          newNames.pop();
        }
        setParticipantNames(newNames);

        // Aktualisiere isManualInput
        const newIsManualInput = [...isManualInput];
        while (newIsManualInput.length < numParticipants) {
          newIsManualInput.push(false);
        }
        while (newIsManualInput.length > numParticipants) {
          newIsManualInput.pop();
        }
        setIsManualInput(newIsManualInput);

        // Aktualisiere manualShares
        const newManualShares = [...manualShares];
        while (newManualShares.length < numParticipants) {
          newManualShares.push('');
        }
        while (newManualShares.length > numParticipants) {
          newManualShares.pop();
        }
        setManualShares(newManualShares);

        setParticipants(numParticipants);
        setError('');
      }
    }

    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            totalAmount={totalAmount}
            handleTotalAmountChange={handleTotalAmountChange}
            error={error}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <Step2
            participants={participants}
            handleParticipantsChange={handleParticipantsChange}
            error={error}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <Step3
            participantNames={participantNames}
            handleNameChange={handleNameChange}
            isManualInput={isManualInput}
            handleManualInputChange={handleManualInputChange}
            manualShares={manualShares}
            handleShareChange={handleShareChange}
            finalShares={finalShares}
            isValid={isValid}
            error={error}
            prevStep={prevStep}
            nextStep={nextStep}
            participants={participants}
          />
        );
      case 3:
        return (
          <Step4
            totalAmount={totalAmount}
            participants={participants}
            finalShares={finalShares}
            participantNames={participantNames}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: 24, marginTop: 48, marginBottom: 48 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Geschenkly Rechner
        </Typography>
        <Stepper activeStep={currentStep} alternativeLabel style={{ marginBottom: 24 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(currentStep)}
      </Paper>
    </Container>
  );
};

export default GiftExpenseSplitter;

import React from 'react';
     import GiftExpenseSplitter from './GiftExpenseSplitter';

     function App() {
       return (
         <div className="App">
           <GiftExpenseSplitter />
         </div>
       );
     }

     export default App;
